import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  Navigate
} from "react-router-dom";
import Page from "../views/Page";

function DynamicRouter(props) {
  var pageArray = Array.from(props.pages);
  var menuArray = Array.from(props.menus);
  //Always place Home page first in array;
  pageArray.some((item, idx) => item.slug === 'home' && pageArray.unshift(pageArray.splice(idx,1)[0]))
  
  return (
    
    <Router>
      <Routes>
        {pageArray.reverse().map((page) => (

          <Route
            key={page.attributes.slug}
            path={page.attributes.slug === "home" ? "/" : "/" + page.attributes.slug}
            element={<Page page={page.attributes} footerData={props.footer.attributes} menus={menuArray} maps={props.maps} careers={props.careers}/>}
          />
        ))}
        <Route path="*" element={<Navigate replace to="/" />}/>
      </Routes>
    </Router>
  );
}

export default DynamicRouter;
