import { Row, Col, Container, Popover, OverlayTrigger, Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import Helpers from '../../../utils/Helpers';


const CompanyBlock = ({ data, classes }) => {
  const [modalShow, setModalShow] = useState(false);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [imgAlt, setImgAlt] = useState('');
  const [url, setUrl] = useState('');

  const handleClose = () => setModalShow(false);
  const handleShow = (index) => {
    setModalShow(true);
    setBody(data.companies[index].body);
    setTitle(data.companies[index].companyName);
    setImgUrl(data.companies[index].logo?.data?.attributes?.formats?.thumbnail.url)
    setImgAlt(data.companies[index].logo?.data?.attributes?.formats?.thumbnail.name)
    setUrl(data.companies[index].urlLink)
  };
  // console.log(data);
  var windowSize = Helpers.useWindowSize();


  const renderCompanies = () => {
    const companies = data.companies;
    var companyList = null;
    if (windowSize.width > 768) {
      companyList = companies.map(company => {
        const popover = (
          <Popover id={company.companyName}>
            <Popover.Body>
              <div className="pop-company-info">
                <img className="pop-company-logo" src={process.env.REACT_APP_STRAPI_API_URL + company.logo?.data?.attributes?.formats?.thumbnail.url} />
                <p className="pop-body">{company.urlLink ? <a className="pop-company-name" target="_blank" href={company.urlLink}>{company.companyName}</a> : <span>{company.companyName}</span> } {company.body}</p>
                {company.urlLink && <a className="pop-url" href={company.urlLink} target="_blank">Learn More</a>}
              </div>
            </Popover.Body>
          </Popover>
        );

        return (
          <Col data-aos={"fade-up"} xs={6} md={4} lg={3} className="company-col" key={company.companyName}>
            <OverlayTrigger rootClose="true" trigger="click" placement="bottom-end" overlay={popover}>
              <div className="logo-container">
                <img className="company-logo" src={process.env.REACT_APP_STRAPI_API_URL + company.logo?.data?.attributes?.formats?.thumbnail.url} />

              </div>
            </OverlayTrigger>
          </Col>
        )
      });
    } else {
      companyList = companies.map((company, i)=> {
        return (
          <Col data-aos={"fade-up"} xs={6} md={4} lg={3} className="company-col" key={company.companyName} >
              <div className="logo-container" onClick={() => handleShow(i)}>
                <img className="company-logo" src={process.env.REACT_APP_STRAPI_API_URL + company.logo?.data?.attributes?.formats?.thumbnail.url} />

              </div>
          </Col>
        )
      });



    }
    return companyList;
  };

  return (
    <Container fluid className={classes + " hero-block "}>
      <Row>
        <Col data-aos={"fade-up"} xs={12} md={12} lg={12} className="header-wrapper">
          <h2 className="sub-header">{data.header}</h2>
        </Col>
      </Row>
      <Row className="company-row">
        {windowSize && renderCompanies()}
        <Modal
        show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
          <Row>
            <a className="close" onClick={handleClose}></a>
          </Row>
          <Row>
          <img className="pop-company-logo" src={process.env.REACT_APP_STRAPI_API_URL + imgUrl} />
            <p className="pop-body"> {url ? <a className="pop-company-name" target="_blank" href={url}>{title}</a> : <span>{title}</span> } {body}</p>
            {url && <a className="pop-url" href={url} target="_blank">Learn More</a>}
            </Row>
          </Modal.Body>
        </Modal>
      </Row>
    </Container>
  );
};

export default CompanyBlock;
