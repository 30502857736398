import { Row, Col, Container } from "react-bootstrap";
import React, { useState } from "react";


const HomeHeroBlock = ({ data, classes }) => {
  return (
    <Container fluid className={classes + " hero-block background-image"} style={{backgroundImage: `url("${process.env.REACT_APP_STRAPI_API_URL}${data?.image?.data?.attributes?.url}")`}}>
      <Row className="align-items-end">
        <Col data-aos={"fade-up"} xs={12} md={5} lg={6} xl={7} className="header-wrapper">

          <h1 className="header">{data.header}</h1>

        </Col>
      </Row>
    </Container>
  );
};

export default HomeHeroBlock;
