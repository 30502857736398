import { Row, Col, Container } from "react-bootstrap";
import React from "react";

const ImageWithFactsBlock = ({ data, classes }) => {

  // console.log(data.facts);
  return (
    <Container fluid className={classes + " hero-block"}>
      <Row>
        <Col className="image-col" data-aos={"fade-up"} xs={12} md={12} lg={5}>
          <div className="image-container">
            <img className="image" src={`${process.env.REACT_APP_STRAPI_API_URL}${data.image?.data?.attributes?.url}`}></img>
          </div>
        </Col>
        <Col data-aos={"fade-up"} xs={12} sm={12} md={12} lg={6}>
          <Row>
            <Col data-aos={"fade-up"} xs={10} md={10} lg={10}>
              <h2 className="header">{data.header}</h2>
              <p className="body">{data.body}</p>
            </Col>
          </Row>
          <Row className="facts-row">
          {data.facts.map((fact) => (
            
            <Col data-aos={"fade-up"} xs={12} sm={12} md={5} lg={5} className="fact">
                <h1 className="fact-header">{fact.stat}</h1>
                <p className="fact-text">{fact.subText}</p>
              </Col>
          ))}

          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ImageWithFactsBlock;
